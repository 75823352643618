<template>
  <div>
    <div>
      <v-card-actions>
        <div class="mr-4">
          <div>
            <v-badge
              color="pink"
              :content="offer.childrenNumber"
            >
              <v-icon>
                mdi-account-circle
              </v-icon>
            </v-badge>
            <span>
              <span
                class="ml-1"
                style="font-size: small;  color: rgb(139, 209, 24);"
              >
                {{ `${offer.executorAssignmentType ? offer.executorAssignmentType.label: ''}` }}
              </span>

            </span>
          </div>
          <span>{{ offer.vacancy.localeTitle ? offer.vacancy.localeTitle : offer.vacancy.title }}</span>
        </div>
        <v-spacer />
        <span style="color: gray; font-size: small">
          {{ offer.supportNumber || '' }}
        </span>
        <v-spacer />
        <div class="mr-4">
          <div>
            <span
              :class="{'selected-amount': isNotSelfEmploymentSelected}"
              style="font-size: 10px; color: gray"
            >Для физлиц (+налоги)</span>
          </div>
          <span
            style="font-size: 1.5em; font-weight: bold"
          >
            {{ fullExpense }} ₽

          </span>
        </div>
        <div>
          <div>
            <span
              :class="{'selected-amount': isNotSelfEmploymentSelected}"
              style="font-size: 10px; color: gray"
            >Для самозанятых</span>
          </div>
          <span
            style="font-size: 1.5em; font-weight: bold"
          >
            {{ selfEmployedFullExpense }} ₽
          </span>
        </div>
      </v-card-actions>
    </div>
    <div
      class="offer-item__header"
    >
      <div class="offer-item__header__line second">
        <AppTextDateTime class="offer-item__header__date-start">
          <template #date>
            <v-btn
              icon
              :disabled="!offer.scheduler"
              :color="offer.scheduler ? 'success' : undefined"
              @click="goToWorkDay"
            >
              <v-icon>
                mdi-calendar-month-outline
              </v-icon>
            </v-btn>
            {{ offer.isScheduler ? `${offer.startDate} - ${offer.endDate}` : offer.startDate }}
          </template>
        </AppTextDateTime>

        <AppTextDateTime class="offer-item__header__date-end">
          <template
            #time
            class="time-template"
          >
            <span class="time-wrapper">
              <v-btn
                icon
                disabled
              >
                <v-icon>
                  mdi-clock-outline
                </v-icon>
              </v-btn>

              {{ offer.startTime }} - {{ offer.endTime }}
            </span>
          </template>
        </AppTextDateTime>
        <div
          class="offer-item__header__break"
        >
          {{ `${$t('offer.break')}: ${!!offer.break && !!offer.break.duration ? offer.break.duration : 0} минут` }}
        </div>
        <div
          class="offer-item__header__right"
        >
          <div
            class="offer-item__header__options options"
          >
            <div
              class="options__item question"
              :title="$t('offer.question')"
            >
              <IconQuestionMark
                width="18"
                height="18"
                activeColor="#8BD118"
                :active="offer.hasQuestionList"
              />
            </div>
            <div
              v-if="offer.weatherCondition"
              class="options__item cold"
              :title="$t('offer.cold')"
            >
              <IconColdMark
                width="18"
                height="18"
                activeColor="#8BD118"
                :active="offer.weatherCondition.title === 'cold'"
              />
            </div>
            <div
              v-if="offer.weatherCondition"
              class="options__item car"
              :title="$t('offer.car')"
              :class="{ active: offer.hasTransportation }"
            >
              <IconBus
                width="18"
                height="18"
                activeColor="#8BD118"
                :active="offer.hasTransportation"
              />
            </div>
            <div
              class="options__item lunch"
              :title="$t('offer.lunch')"
            >
              <IconFreeEat
                width="18"
                height="13"
                activeColor="#8BD118"
                :active="offer.freeFood"
              />
            </div>
            <div
              class="options__item lunch"
              :title="$t('offer.vaccination')"
            >
              <IconQrCode
                width="13"
                height="13"
                activeColor="#8BD118"
                :active="offer.requiredVaccination"
              />
            </div>
          </div>
          <div class="offer-item__header__hour">
            {{ offer.duration }}
          </div>
        </div>
      </div>

      <template>
        <div class="offer-item__header__line">
          <AppTextDateTime
            class="offer-item__header__date-create"
            style="color: #4B4B4B !important;"
          >
            <template #date>
              {{ offer.createdDate }}
            </template>
            <template #time>
              {{ offer.createdTime }}
            </template>
          </AppTextDateTime>

          <AppButton
            v-if="!offer.isScheduler &&
              (offer.childrenNumber > 0 && (offer.status === 'active' || offer.status === 'casting'))"
            class="wb-red-button offer-item__header__line__button"
            @click.native="closeOffer"
          >
            Отменить
          </AppButton>
          <div
            v-if="offer.isScheduler"
            class="offer-item__header__status"
          >
            {{ offer.deletedAt ? 'Закрыт' : 'В работе' }}
          </div>
          <div
            v-else
            class="offer-item__header__status"
          >
            {{ offer.status === 'active' ? 'Активен' : 'Завершен' }}
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import IconColdMark from '@/components/common/icons/IconColdMark'
import IconQrCode from '@/components/common/icons/IconQrCode'
import IconFreeEat from '@/components/common/icons/IconFreeEat'
import IconBus from '@/components/common/icons/IconBus'
import IconQuestionMark from '@/components/common/icons/IconQuestionMark'
import AppTextDateTime from '@/components/common/AppTextDateTime'
import AppButton from '@/components/common/simple/AppButton'
import { DATE_FORMAT } from '@/utils/date_utils'
import { routeList } from '@/router/office'

export default {
  name: 'OfferItemHeader',
  components: {
    AppButton,
    AppTextDateTime,
    IconQrCode,
    IconQuestionMark,
    IconColdMark,
    IconFreeEat,
    IconBus
  },
  props: {
    offer: {
      type: Object,
    },
    selectedUser: {
      type: Object,
    }
  },
  data () {
    return {
      showFull: false
    }
  },
  computed: {
    fullExpense () {
      return this.offer.childrenNumber
        ? parseFloat(this.offer.expense * this.offer.childrenNumber).toFixed(2)
        : parseFloat(this.offer.expense).toFixed(2)
    },
    selfEmployedFullExpense () {
      return this.offer.childrenNumber
        ? parseFloat(this.offer.selfEmployerExpense * this.offer.childrenNumber).toFixed(2)
        : parseFloat(this.offer.selfEmployerExpense).toFixed(2)
    },
    hasLaborer () {
      return this.selectedUser && this.selectedUser.laborer
    },
    isSelfEmploymentSelected () {
      if (!this.hasLaborer) {
        return false
      }
      return this.selectedUser.laborer.isSelfEmployed
    },
    isNotSelfEmploymentSelected () {
      if (!this.hasLaborer) {
        return false
      }
      return !this.selectedUser.laborer.isSelfEmployed
    },
    additionalInfo () {
      const { offer } = this
      const list = [
        { name: 'Корпоративный транспорт', value: 'hasTransportation' },
        { name: 'Бесплатный обед', value: 'freeFood' },
      ]
      if (!offer) return []
      return list.filter((item) => offer[item.value])
    }
  },
  methods: {
    getMonth (month) {
      return DATE_FORMAT.long[month]
    },

    goToWorkDay () {
      if (!this.offer.scheduler) {
        return
      }
      this.$router.push({
        name: routeList.OFFICE_OFFER_WORKDAY_LIST_ROUTE_NAME,
        params: { id: this.getActiveOffice.id, offerId: this.offer.scheduler }
      })
    },
    closeOffer () {
      this.$emit('cancelOffer')
      // this.$store.dispatch(
      //   'offer/fetchOffersCancelById',
      //   { idList: [{ id: this.offer.id, canceledNumber: 1 }] }
      // )
      //   .then(() => {
      //     this.$store.dispatch('offer/fetchOffersListByOffice', { statusGroup: 'active' })
      //     this.$router.push({
      //       name: routeList.OFFICE_OFFER_LIST_NAME,
      //       params: { id: this.$route.params.id },
      //       query: { statusGroup: 'active' }
      //     })
      //   })
    }
  }
}
</script>

<style lang="sass" scoped>
.selected-amount
  color: #8BD118 !important

.expense-label
  font-style: normal
  font-weight: 500
  font-size: 12px
  line-height: 15px
  color: #000000
  opacity: 0.2
.offer-item__header
  font-family: $mainFont
  display: flex
  flex-direction: column
  align-items: center
  background-color: white
  padding: 15px 30px
  box-shadow: 0px 5px 15px rgba(117, 117, 117, 0.1)
  border-radius: 6px
  &__break
    @include fontStyle(500, 14px)
    margin-left: 29px
  &__date-create
    font-size: 16px
  &__right
    margin-left: auto
    display: flex
    align-items: center
  .options
    display: flex
    align-items: center
    margin-right: 77px
    &__item
      margin: 0 5px
      cursor: pointer
      display: flex
      align-items: center
      &.question
        margin: 0
      &.active
        svg
          path
            fill: #8BD118 !important
      svg
        max-width: 100%
        max-height: 100%
  &__date-start
    display: flex
    align-items: center
    color: #1a1a1a
    margin-right: 20px
    border: none
    padding: 0
    @include fontStyle(700, 14px)
    .calendar-icon
      margin-right: 15px
  &__date-end
    @include fontStyle(800, 14px)
    color: #1a1a1a
    .time-wrapper
      display: flex
      align-items: center
    .time-icon
      margin-right: 12px
  &__line
    position: relative
    display: flex
    align-items: center
    width: 100%
    border-top: $default_border_bottom
    margin: 0 0 13px
    &__button
      margin-left: 20px
      padding-left: 0
    &.first
      border: none
    &.second
      margin-bottom: 0
      padding: 5px 0 2px
    &:last-of-type
      border-bottom: 0
      margin-bottom: 0
      padding-top: 18px
  &__vacancy-name
    margin-left: 15px
    margin-right: 15px
    @include fontStyle(800, 22px)

  &__dropper
    @include fontStyle(600, 13px)
    cursor: pointer
    text-decoration-line: underline
    color: rgba(0, 0, 0, 0.5)

  &__money-info, &__hour-info
    @include fontStyle(500, 12px)
    margin-left: auto
    margin-right: 10px
    width: 180px
    text-align: right
    color: rgba(0, 0, 0, 0.2)

  &__money, &__hour
    @include fontStyle(800, 29px)
    text-align: right

  &__hour
    font-size: 20px
    background: #F0F0F1
    border-radius: 6px
    padding: 8px
  &__temperature
    @include fontStyle(500, 18px)
    &__value
      font-weight: 600
  &__additional-info
    @include fontStyle(600, 18px)
    margin-left: 10px
  &__status
    @include fontStyle(600, 16px)
    margin-left: auto
    margin-right: 21px
    color: #8BD118
  &__view-count
    @include fontStyle(500, 16px)
    color: rgba(0, 0, 0, 0.2)
    margin-left: 5px
</style>
